@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
}

html,
body {
	min-height: 100vh;
	overflow-x: hidden;
	font-family: "Roboto", sans-serif;
}

:root {
	--header-height: 88px;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	width: 100% !important;
}

/* Ensure the input within the container also takes full width */
.react-datepicker__input-container > input {
	width: 100% !important;
}
/* Ensure uniform appearance of select elements across different browsers and devices.
   The default appearance is overridden to prevent variations in how select elements are rendered
   by different user agents, ensuring a consistent look and feel with a custom dropdown arrow. */
select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: #fff
		url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="none" stroke="%23333" stroke-width=".5" d="M0 0l2 2 2-2"/></svg>')
		no-repeat right 10px center;
	background-size: 10px;
	border: 1px solid #ccc;
	border-radius: 0.375rem; /* Tailwind's rounded-md */
	padding: 10px;
	padding-right: 30px; /* Add space for the custom arrow */
	font-size: 16px;
	color: #333;
	line-height: 1.5;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

select:focus {
	outline: none;
	border-color: #3b82f6; /* Tailwind's blue-500 */
	box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5); /* Tailwind's focus ring color */
}

select:hover {
	border-color: #6b7280; /* Tailwind's gray-400 */
}

select option {
	color: #374151; /* Tailwind's gray-700 */
}
